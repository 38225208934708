import {
  Modal,
  Nav,
  Tab,
  Row,
  Col,
  Image,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { BsQuestionCircle } from "react-icons/bs";
import { Score } from "@/assets/images";
import { SingleChart } from "@/pages/index";
import { blendedTeamCost, blendedTeamQualityError } from "@/utils/activity";
import { TeamPerformanceTable } from "@/components/table";
import { useEffect, useState } from "react";
import { modal, table } from "@/utils/constant";

function TeamPerformanceModal({
  show,
  hide,
  gameData,
  teamName,
  singleActivityCount,
}) {
  const [activeKey, setActiveKey] = useState("first");

  const handleSelect = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    const scrolling = document.getElementById("scroll");
    scrolling.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);
  // tooltip content
  const renderTooltip = (props) => (
    <Tooltip className="tooltip-light" id="button-tooltip" {...props}>
      {modal?.teamPerformanceModalTooltip}
    </Tooltip>
  );

  let blendedScore =
    gameData.teamQualityError / blendedTeamQualityError +
    gameData.teamCost / blendedTeamCost / 2;
  let qualityloss = gameData.teamQualityError;
  let excessCost = gameData.teamCost;

  return (
    <>
      <Modal
        show={show}
        className="px-0"
        onHide={hide}
        size="xl"
        centered
        scrollable
      >
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title>{teamName}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="scroll" className="py-10 px-lg-14 chart-modal">
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Nav variant="pills" className="justify-content-center mb-10">
              <Nav.Item>
                <Nav.Link className="rounded-end-0 px-10" eventKey="first">
                  {table?.quality}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="rounded-0 px-10" eventKey="second">
                  {table?.financial}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="rounded-start-0 px-10" eventKey="third">
                  {table?.summary}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="third">
                <div className="hstack gap-xl-10 gap-0 flex-wrap justify-content-xl-start justify-content-center">
                  <Image src={Score} fluid />
                  <div className="max-w-sm-max">
                    <Row className="gx-xl-20">
                      <Col className="col-auto text-center">
                        <h4 className="text-center ms-sm-6 mb-6">
                          {modal?.teamPerformanceQuality}
                        </h4>
                        <SingleChart data={qualityloss} name={"Quality Loss"} />
                      </Col>
                      <Col>
                        <h4 className="col-auto text-center ms-sm-8 mb-6">
                          {modal?.teamPerformanceExcess}
                        </h4>
                        <SingleChart data={excessCost} name={"Excess Cost"} />
                      </Col>
                    </Row>
                    <hr className="d-sm-block d-none border-black border-2 border-top mt-n3 me-md-n8 me-sm-n6" />
                    <p className="text-md font-bold hstack justify-content-center">
                      <OverlayTrigger
                        placement="top-start"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <span>
                          <BsQuestionCircle className="me-1 mb-1 text-15" />
                        </span>
                      </OverlayTrigger>
                      {modal?.blendedScore} {blendedScore.toFixed(2)}
                    </p>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <TeamPerformanceTable
                  gameData={gameData}
                  name="Cost"
                  singleActivityCount={singleActivityCount}
                  setActiveKey={setActiveKey}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="first">
                <TeamPerformanceTable
                  gameData={gameData}
                  name="Error"
                  singleActivityCount={singleActivityCount}
                  setActiveKey={setActiveKey}
                  activeKey={activeKey}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TeamPerformanceModal;
