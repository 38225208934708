import { React, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  BsFillBarChartLineFill,
  BsPauseFill,
  BsPlayFill,
} from "react-icons/bs";
import { supabase } from "../config/supabase";
import { ChallengeModal, EditTimeModal, GroupTable, PauseModal } from "./index";
import { defaultRounds, ltDefaultRounds } from "../utils/activity";

import {
  defaultAmbulanceDiversionsCost,
  defaultAmbulanceDiversionsError,
  defaultArrivalsWaitingCost,
  defaultArrivalsWaitingError,
  defaultExtraStaffCost,
  defaultExtraStaffError,
  defaultHoursNotComplete,
  defaultHoursNotCompleteCost,
  defaultPatientsWaitingCost,
  defaultPatientsWaitingError,
  defaultRequestWaitingError,
} from "../utils/activity";
import { useNavigate } from "react-router-dom";
import { encrypt } from "../utils/crypto";
import { table } from "../utils/constant";
import moment from "moment";

function Group({ sessionData, getTeam }) {
  const [show, setShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [challengeModal, setChallengeModal] = useState(false);
  const [allTeam, setAllTeams] = useState([]);
  const nevigate = useNavigate();

  let isGameComplete = sessionData?.is_game_complete
    ? sessionData?.is_game_complete === 1
    : false;
  // getTeam?.data?.length &&
  // getTeam?.data?.every((team) => team.is_game_complete === 1);

  let isGameStart =
    getTeam?.data?.length &&
    getTeam?.data?.every((team) => team.current_status >= 3);
  const getGroupTeamData = async () => {
    try {
      if (sessionData?.id) {
        const { data } = await supabase
          .from("team")
          .select("*")
          .eq("session_id", sessionData?.id)
          .order("team_index", { ascending: true });
        const teamWithGames = await getGroupTeamGameData(data);
        setAllTeams(teamWithGames);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getGroupTeamGameData = async (data) => {
    try {
      const teamDataPromises = data?.map(async (team, index) => {
        const { data: games } = await supabase
          .from("games")
          .select("*")
          .match({
            session_id: team?.session_id,
            team_id: team?.id,
          })
          .lt(
            "round_no",
            team.current_round !== defaultRounds
              ? team.current_round
              : ltDefaultRounds,
          );
        return { ...team, games };
      });
      return await Promise.all(teamDataPromises);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    supabase
      .channel("custom-update-channel")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "team" },
        () => {
          getGroupTeamData();
        },
      )
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getGroupTeamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeam, sessionData]);

  const handlePauseGame = () => {
    setShow(!show);
  };
  const editModalShow = () => {
    setEditModal(!editModal);
  };
  const challengeModalShow = () => {
    setChallengeModal(!challengeModal);
  };

  const countQualityErrors = (
    gameData,
    current_round,
    game_complete_status,
  ) => {
    // ACTIVITY
    let ambulanceDiversionsActivity = 0;
    let patientsWaitingActivity = 0;
    let arrivalsWaitingActivity = 0;
    let requestWaitingActivity = 0;
    let extraStaffActivity = 0;

    if (!gameData) {
      return {
        activity: {
          ambulanceDiversionsActivity,
          patientsWaitingActivity,
          arrivalsWaitingActivity,
          requestWaitingActivity,
          extraStaffActivity,
        },
        teamCost: 0,
        teamQualityError: 0,
      };
    }
    // QUALITY ERRORS
    let ambulanceDiversions = 0;
    let patientsWaiting = 0;
    let arrivalsWaiting = 0;
    let requestWaiting = 0;
    let extraStaff = 0;
    let noOfHoursNotComplete = 0;

    // COST
    let ambulanceDiversionsCost = 0;
    let patientsWaitingCost = 0;
    let arrivalsWaitingCost = 0;
    let extraStaffCost = 0;
    let noOfHoursNotCompleteCost = 0;
    for (const data of gameData) {
      // ACTIVITY
      const totalRequestWaiting = [
        "coming_from_emergency",
        "coming_from_critical",
        "coming_from_surgery",
      ].reduce(
        (sum, key) =>
          sum +
          (data?.[key]?.filter((waiting) => waiting.status === 1)?.length || 0),
        0,
      );
      ambulanceDiversionsActivity +=
        Number(data?.ambulance_diversions_count) || 0;
      patientsWaitingActivity += Number(data?.patients_waiting_count) || 0;
      arrivalsWaitingActivity +=
        (data?.role_id !== 1 && Number(data?.data?.walking?.patient_count)) ||
        0;
      requestWaitingActivity += Number(totalRequestWaiting) || 0;
      extraStaffActivity += Number(data.total_extra_staff) || 0;

      if (data?.role_id === 1) {
        // QUALITY ERRORS
        ambulanceDiversions +=
          Number(data?.ambulance_diversions_count) *
            defaultAmbulanceDiversionsError || 0;
        patientsWaiting +=
          Number(data?.patients_waiting_count) * defaultPatientsWaitingError ||
          0;
        // COST
        ambulanceDiversionsCost +=
          Number(data?.ambulance_diversions_count) *
            defaultAmbulanceDiversionsCost || 0;
        patientsWaitingCost +=
          Number(data?.patients_waiting_count) * defaultPatientsWaitingCost ||
          0;
      } else {
        // QUALITY ERRORS
        arrivalsWaiting +=
          Number(data?.data?.walking?.patient_count) *
          defaultArrivalsWaitingError;
        requestWaiting +=
          Number(totalRequestWaiting) * defaultRequestWaitingError;
        // COST
        arrivalsWaitingCost +=
          (data?.role_id !== 1 && Number(data?.data?.walking?.patient_count)) *
          defaultArrivalsWaitingCost;
      }
      extraStaffCost += Number(data.total_extra_staff) * defaultExtraStaffCost;
      extraStaff += Number(data.total_extra_staff) * defaultExtraStaffError;
    }

    if (current_round > 1) {
      // if rounds is 24 then no need calculation
      let notCompletedRounds;
      if (
        defaultRounds === current_round &&
        game_complete_status === "times-complete"
      ) {
        notCompletedRounds = 1;
      } else {
        notCompletedRounds = defaultRounds - current_round;
      }
      noOfHoursNotComplete += notCompletedRounds * defaultHoursNotComplete;
      noOfHoursNotCompleteCost +=
        notCompletedRounds * defaultHoursNotCompleteCost;
    }
    const teamQualityError =
      ambulanceDiversions +
      patientsWaiting +
      arrivalsWaiting +
      requestWaiting +
      extraStaff +
      noOfHoursNotComplete;
    const teamCost =
      ambulanceDiversionsCost +
      patientsWaitingCost +
      arrivalsWaitingCost +
      extraStaffCost +
      noOfHoursNotCompleteCost;
    // ACTIVITY
    let activity = {
      ambulanceDiversionsActivity,
      patientsWaitingActivity,
      arrivalsWaitingActivity,
      requestWaitingActivity,
      extraStaffActivity,
    };

    return { activity, teamCost, teamQualityError };
  };

  const handleTeamPerform = () => {
    nevigate(`/teams-performance/${encrypt(String(sessionData.id))}`);
  };

  const handlePause = async (type) => {
    const promises = allTeam?.map(async (element) => {
      let session = {};

      const isGameOngoing =
        element.game_start_time !== null && element.is_game_complete === 0;

      if (type === "pause_game" && isGameOngoing) {
        session = { pause_date_time: new Date() };
        try {
          await supabase
            .from("session")
            .update({ is_game_pause: true })
            .eq("id", sessionData.id);
          handlePauseGame();
        } catch (error) {
          console.error("Error updating session for pause:", error);
        }
      } else if (isGameOngoing) {
        try {
          const momentNow = moment(new Date().toISOString());
          const pauseDuration = momentNow.diff(element.pause_date_time);
          const totalPauseTime = Math.floor(pauseDuration / 1000) + 1;

          session = {
            pause_date_time: null,
            total_pause_time: element.total_pause_time + totalPauseTime,
          };

          await supabase
            .from("session")
            .update({ is_game_pause: false })
            .eq("id", sessionData.id);
        } catch (error) {
          console.error("Error updating session for resume:", error);
        }
      }

      if (Object.keys(session).length > 0) {
        try {
          await supabase.from("team").update(session).eq("id", element.id);
        } catch (error) {
          console.error("Error updating team:", error);
        }
      }
    });

    await Promise.all(promises);
  };

  const is_pause = allTeam?.some((item) => item?.pause_date_time !== null);
  let isDisabled = false;
  const getTeamStatus = getTeam?.data?.some((item) => item.current_status > 4);
  if (getTeamStatus) {
    if (sessionData?.orientation_video_start && isGameComplete) {
      isDisabled = true;
    }
  } else {
    isDisabled = true;
  }
  return (
    <>
      <div className="hstack flex-wrap gap-3 mb-6">
        <Button
          disabled={isDisabled}
          variant="outline-primary"
          onClick={() =>
            !is_pause ? handlePauseGame() : handlePause("resume_game")
          }
        >
          {!is_pause ? (
            <span>
              <BsPauseFill className="me-1 mt-n1 text-lg" /> Pause Countdown
            </span>
          ) : (
            <>
              <BsPlayFill className="me-1 mt-n1 text-lg" /> Resume Countdown
            </>
          )}
        </Button>
        {/* <Button variant="outline-primary" onClick={editModalShow}>
          <BsHourglassSplit className="me-2 mt-n1 text-lg" />
          Edit Time Remaining
        </Button>
        <Button variant="outline-primary" onClick={challengeModalShow}>
          <BsFlagFill className="me-2 mt-n1" />
          Challenge: Reorganize!
        </Button> */}
        {isGameComplete && (
          <Button onClick={handleTeamPerform} variant="outline-primary">
            <BsFillBarChartLineFill className="me-2 mt-n1" />
            {table?.teamPerformance}
          </Button>
        )}
      </div>

      <GroupTable
        allTeam={allTeam}
        countQualityErrors={countQualityErrors}
        isGameComplete={isGameComplete}
        isGameStart={isGameStart}
        sessionData={sessionData}
      />
      {show && (
        <PauseModal
          show={show}
          hide={() => handlePauseGame()}
          handlePause={() => handlePause("pause_game")}
        />
      )}
      {editModal && (
        <EditTimeModal show={editModal} hide={() => editModalShow()} />
      )}
      {challengeModal && (
        <ChallengeModal show={true} hide={() => challengeModalShow()} />
      )}
    </>
  );
}
export default Group;
