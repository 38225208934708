import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_CRYPTO_SECRET_KEY;

export const encrypt = (text) => {
  if (text) {
    const encryptedText = CryptoJS.AES.encrypt(text, secretKey).toString();
    return encodeURIComponent(encryptedText);
  }
};

export const decrypt = (encryptedText) => {
  try {
    if (encryptedText) {
      const decryptedBytes = CryptoJS.AES.decrypt(
        decodeURIComponent(encryptedText.toString()),
        secretKey,
      );
      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedText;
    }
  } catch (error) {
    console.error("Error decrypting data:", error);
    return "Decryption error";
  }
};
