import * as Yup from "yup";

export const loginValidate = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
      "Password must be 8 to 15 characters and include at least one number and one special character.",
    ),
});

export const updateValidate = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
      "Password must be 8 to 15 characters and include at least one number and one special character.",
    ),
});

export const resetPassValidate = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const addParticipantValidate = Yup.object({
  email: Yup.string()
    .email("Please use a valid email address")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please use a valid email address"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
});

export const sessionModalValidate = Yup.object({
  name: Yup.string().required("Please name this session."),
  date: Yup.string().required("Please select a date."),
  amount: Yup.string().required("Amount is required"),
  facilitator: Yup.string().required("Please select a facilitator."),
  count: Yup.number().required("Count is required"),
  payment: Yup.string().required("Payment is required"),
});
