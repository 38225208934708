import { Header, SessionHeader, ConnectionHeader } from '@/components';
import { useLocation } from 'react-router-dom';
function CommonLayout({
	children,
	session_id,
	guestParticipants,
	activeKey,
	handleShow,
	handleAscending,
	isAscending,
	refetch,
}) {
	const location = useLocation();
	return (
		<>
      <Header
        handleShow={handleShow}
        handleAscending={handleAscending}
        session_id={session_id}
        guestParticipants={guestParticipants}
        location={location}
      />
			{location?.pathname !== '/connection' ? (
				<SessionHeader
					guestParticipants={guestParticipants}
					isAscending={isAscending}
					activeKey={activeKey}
					handleAscending={handleAscending}
					refetch={refetch}
				/>
			) : (
				<ConnectionHeader />
			)}
			{children}
		</>
	);
}
export default CommonLayout;
