import { useQuery, useMutation, useQueryClient } from "react-query";
import * as sessionApi from "@/api/index";
import { constant } from "../utils/constant";

const getKey = "buttonText";

export const usePatchButtonText = () => {
  const queryClient = useQueryClient();

  const { data: buttonText = constant?.openGameLink } = useQuery(getKey, {
    queryFn: () => Promise.resolve(constant?.openGameLink),
    staleTime: Infinity,
  });

  const setButtonText = async (newButtonText) => {
    await queryClient.setQueryData(getKey, newButtonText);
  };

  return {
    buttonText,
    setButtonText,
  };
};

export function useGetSessions(id) {
  return useQuery(["sessions", [id]], () => sessionApi.useFetchSession(id));
}
export function useGetSingleSessions(id) {
  return useQuery(["singleSessions", [id]], () =>
    sessionApi.useFetchSingleSession(id),
  );
}

export function useRemoveSession(id) {
  return useMutation(sessionApi.useCancelSession);
}

export function usePostSession() {
  return useMutation(sessionApi.useAddSession);
}

export function useUpdateSession() {
  return useMutation(sessionApi.useUpdateSessionData);
}

export function useGameUpdateStatus() {
  return useMutation(sessionApi.useStartGameUpdateStatus);
}
