import { React } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { modal } from "@/utils/constant";

function removePlayerModal({ show, hide, handleRemove }) {
  return (
    <>
      <Modal show={show} onHide={hide} size="lg" centered>
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title>{modal?.removePlayerModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-10 px-lg-14">
          <p className="mb-3">{modal?.removePlayerModalBodyText}</p>
          <Row className="mt-8">
            <Col>
              <Button
                variant="secondary"
                className="font-semibold w-full"
                onClick={() => handleRemove()}
              >
                {modal?.removePlayerModalButtonsSubmit}
              </Button>
            </Col>
            <Col>
              <Button
                variant="outline-primary"
                className="font-semibold w-full"
                onClick={hide}
              >
                {modal?.removePlayerModalCancel}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default removePlayerModal;
