import { useQuery } from "react-query";
import * as userApi from "@/api/index";

export function useGetUsers() {
  return useQuery("users", userApi.useFetchUser);
}

export function useOrganizationUser(id) {
  return useQuery(["organization", [id]], () =>
    userApi.useFetchOrganizationUser(id),
  );
}
