import React from 'react';
import { Container } from 'react-bootstrap';

const ConnectionHeader = () => {
	return (
		<div className='bg-light py-7'>
			<Container>
				<div className='hstack'>
					<h1 className='text-primary text-futura-pt'>
						{'Test Your Connection'}
					</h1>
				</div>
			</Container>
		</div>
	);
};
export default ConnectionHeader;
